
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import i04 from "../../img/1280/16x9/mail_0001.jpg"

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Image = makeShortcode("Image");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Image src={i04} mdxType="Image" />
    <h1>{`Mail Spoofing`}</h1>
    <p>{`Bella's Mail wurde abgefangen und mitgelesen. - Nun hast du die Daten über
Nutzernamen, E-Mail und Passwort. Eins davon wird dir helfen, in den nächsten
Raum zu gelangen.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;