import { NUMBER_OF_RIDDLES } from "../../const/setup"

const gameInitialState = {
  game: {
    isInitialized: true,
    isResetForm: false,
    isDebugOn: false,
    isToBeLoginShown: false,
    toBeLoginName: "",
    toBeLoginCode: "",
    isOpenFeedback: false,
    isOpenTimeUp: false,
    isOnPause: false,
    isTimeUp: false,
    isSuccess: false,
    hasHints: false,
    durationSuccess: {},
    expiryTimestamp: null,
    riddlePageURLs: [],
    riddleHeadings: [
      "Erfolg",
      "01 Formular",
      "02 What's App Chat",
      "03 Passwörter",
      "04 E-Mail",
      "05 Info-Mail",
      "06 Login Instragram",
      "07 Instagram Profil",
      "08 Login Paypal",
      "09 Paypal Profil",
      "10 2 Faktor Authentifizierung",
      "11 Success"
      

    ],
    codes: [
      "", // depends on loginName
      "Bella Wagner",
      "Netflix",
      "AleimPm4Z&eK!",
      "bella1305!",
      "Verschlüsselung",
      "bella1305!",
      "4",
      "bella1305!",
      "Lüneburg",
      "5363"
    ],
    attemptsCount: Array(NUMBER_OF_RIDDLES + 1).fill(0),
    solved: Array(NUMBER_OF_RIDDLES + 1).fill(false),
    hintsCount: Array(NUMBER_OF_RIDDLES + 1).fill(0),
    language: "de",
  },
}

export default gameInitialState
