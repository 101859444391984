
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import i01 from "../../img/1280/16x9/Bildschirmfoto 2022-01-18 um 10.49.25.png"

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Image = makeShortcode("Image");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Image src={i01} mdxType="Image" />
    <h1></h1>
    <p>{`Dein Ziel im Spiel ist es, dieses Fake-Profil auszufüllen! Drucke dir dazu diese
Seite aus oder skizziere Sie. Erinnerst du dich noch an den Namen? Das ist dein
erster Code.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;