import { durationToSeconds } from "../functions/utils/durationAndSeconds"
import formatDuration from "date-fns/formatDuration"
import { enUS as en, de } from "date-fns/locale"

export const NUMBER_OF_RIDDLES = 11
export const LENGTH_URL = 8
export const CUSTOM_ALPHABET = "abcdefghijkmpqrstuvwxyz"
export const CUSTOM_ALPHABET_NUMBERS = "abcdefghijkmpqrstuvwxyz123456789"
export const EXPIRY_IN_DAYS = 30

const DURATION = { hours: 0, minutes: 45, seconds: 0 }
const locales = { en, de }

export const SETUP = {
  loginNameDefault: "a.b@c.de",
  loginCodeDefault: "14c9180c1e903z1",
  hasHints: false,
  duration: DURATION,
  durationInSec: durationToSeconds(DURATION),
  feedbackHeaderYes: {
    en: [
      "Well done!",
      "Good job!",
      "Perfect.",
      "Really good.",
      "Awesome!",
      "Brilliant!",
    ],
    de: [
      "Sehr gut!",
      "Gut gemacht!",
      "Perfekt.",
      "Wirklich klasse.",
      "Spitze!",
      "Genial!",
    ],
  },
  feedbackBodyYes: {
    en: [
      "Correct. But hurry up. Time is ticking...",
      "Very good. Go on quickly...",
      "Nice. Keep going...",
      "Great. One more done...",
      "Very clever. Now, concentrate on the next one.",
      "Not bad. Good thinking.",
      "Very smart. More of that...",
    ],
    de: [
      "Korrekt. Aber beeilt euch. Die Zeit läuft...",
      "Sehr gut. Macht schnell weiter...",
      "Schön. Auf ein Neues...",
      "Großartig. Eins mehr geschafft...",
      "Sehr klug. Konzentriert euch jetzt auf das nächste Rätsel.",
      "Nicht schlecht. Gut nachgedacht.",
      "Sehr clever. Davon mehr...",
    ],
  },
  feedbackHeaderNo: {
    en: ["Oh no.", "Not really.", "Wrong!", "Bad news.", "Damn!", "Arghh!"],
    de: [
      "Oh nein.",
      "Nicht wirklich.",
      "Falsch!",
      "Schlechte Nachrichten.",
      "Verdammt!",
      "Ups!",
    ],
  },
  feedbackBodyNo: {
    en: [
      "Unfortunately wrong. Please try again. You can do it.",
      "You need to think harder...",
      "Unfortunately, you just need to give it another try...",
      "Don't give up. Just go over the information again.",
      "Not good enough, but you can do it.",
      "You must have overlooked something.",
      "Give it another try...",
    ],
    de: [
      "Leider falsch. Versucht's nochmal. Ihr schafft das.",
      "Darüber müsst ihr noch mal nachdenken...",
      "Leider nein. Noch ein Versuch bitte...",
      "Nicht aufgeben. Guckt euch die Informationen noch mal an.",
      "Nicht gut genug, aber ihr könnt das.",
      "Ihr müsst irgendetwas übersehen haben.",
      "Noch ne Runde...",
    ],
  },
  gameStartedHead: {
    en: "The game has started!",
    de: "Das Spiel hat begonnen!",
  },
  gameStartedBody: {
    en: `You have: ${formatDuration(DURATION)}. Good luck! `,
    de: `Ihr habt: ${formatDuration(DURATION, {
      locale: locales["de"],
    })}. Viel Glück!`,
  },
  loginFailedHead: { en: "Login failed!", de: "Login fehlgeschlagen!" },
  loginFailedBody: {
    en: `Email or Code are wrong. Please try again. If this does not work your code maybe expired. This happens after ${EXPIRY_IN_DAYS} days.`,
    de: `Email oder Code sind falsch. Versucht es noch einmal. Falls das nicht hilft, ist der Code möglicherweise abgelaufen. Das passiert nach ${EXPIRY_IN_DAYS} Tagen.`,
  },
  msgSuccessHead: { en: "Yeah, you made it!", de: "Klasse, geschafft." },
  msgSuccessBody: {
    en: "Congratulations. If you played against other teams compare your times. But in any case go and celebrate.",
    de: "Herzliche Glückwunsch. Falls ihr gegen andere Teams gespielt habt, vergleicht eure Zeiten. In jedem Fall solltet ihr jetzt aber euren Erfolg feiern.",
  },
  timeUpHead: { en: "Time is up!", de: "Die Zeit is um!" },
  timeUpBody: {
    en: "What a shame. You did not complete in time. But you are welcome to play it to the end. If you make it...",
    de: "Wie schade! Ihr habt es nicht in der Zeit geschafft. Aber ihr könnt natürlich noch bis zum Ende weiterspielen. Wenn ihr's hinkriegt...",
  },
  riddlePageURLs: [],
  riddleHeadings: [
    "Erfolg",
    "01 Formular",
    "02 What's App Chat",
    "03 Info Passwörter",
    "04 E-Mail",
    "05 Info Mail",
    "06 Login Instagram",
    "07 Instagram Profil",
    "08 Login Paypal",
    "09 Paypal Profil",
    "10 Zwei-Faktor-Authetifizierung",
    "11 Data-Leak",
    "12 Success!"
  ],
  codes: [
    "", // depends on loginName
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12"
  ],
  languages: ["de"],
  attemptsLang: "Attempts",
  solvedLang: { en: "Solved", de: "Gelöst" },
  hintLang: { en: "Hint", de: "Hinweis" },
  hintsLang: { en: "Hints", de: "Hinweise" },
  hintValue: { minutes: 5 },
}
