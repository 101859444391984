
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import i05 from "../../img/1280/16x9/emails.jpg"

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Image = makeShortcode("Image");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Image src={i05} mdxType="Image" />
    <h1>{`E-Mail Verschlüsselung`}</h1>
    <p>{`Welches Prinzip nutzt man, um seine Daten sicherer zu machen?`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;