import { NUMBER_OF_RIDDLES, SETUP } from "../../const/setup"
import produce from "immer"
import { nanoURLs } from "../../functions/utils/nanoid"
import {
  durationToSeconds,
  secondsToDuration,
} from "../../functions/utils/durationAndSeconds"
import {
  shortHashGen,
  keyGen,
  expiryTimestampRecover,
} from "../../functions/utils/hashes"

import {
  codeUpdate,
  durationSuccessUpdate,
  isOpenFeedbackFalse,
  isOpenTimeUpFalse,
  isTimeUpUpdate,
  isOnPauseUpdate,
  isToBeLoginShownUpdate,
  toBeLoginNameUpdate,
  isDebugOnUpdate,
  hintsUpdate,
  languageUpdate,
} from "../actions/game"

const game = (slice = [], action) => {
  // ==============================================================
  let sliceNext = slice

  const { payload } = action

  if (sliceNext && sliceNext.isResetForm) {
    sliceNext = produce(sliceNext, (sliceDraft) => {
      sliceDraft.isResetForm = false
    })
  }

  switch (action.type) {
    // ---------------------------------------------------------
    case codeUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        if (!sliceDraft.solved[payload.rNb]) {
          sliceDraft.attemptsCount[payload.rNb] += 1
        }

        // license code
        if (payload.rNb === 0) {
          const hash = payload.code.slice(0, 8)
          const expiryDaySprinkled = payload.code.slice(8)
          const shortHash = shortHashGen(payload.loginName)

          if (hash === shortHash) {
            const expiryTimestamp = expiryTimestampRecover(expiryDaySprinkled)
            const now = +Date.now()
            if (now < expiryTimestamp) {
              sliceDraft.solved[payload.rNb] = true
            }
          }
        }
        // riddle code matches
        else if (
          payload.code.toUpperCase() ===
          sliceDraft.codes[payload.rNb].toUpperCase()
        ) {
          sliceDraft.solved[payload.rNb] = true
        }

        // set expiryTimestamp to start countdown
        if (payload.rNb === 0 && sliceDraft.solved[payload.rNb]) {
          const now = new Date()
          sliceDraft.expiryTimestamp = now.setSeconds(
            now.getSeconds() + SETUP.durationInSec
          )
          sliceDraft.riddlePageURLs = nanoURLs({
            seed: payload.loginName,
            nb: NUMBER_OF_RIDDLES + 1,
          })
        }
        // success achieved
        else if (
          payload.rNb === NUMBER_OF_RIDDLES &&
          sliceDraft.solved[payload.rNb]
        ) {
          sliceDraft.isSuccess = true
        }
        sliceDraft.isOpenFeedback = true
      })
      return sliceNext

    // ---------------------------------------------------------
    case hintsUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.hintsCount[payload.rNb] = Math.max(
          sliceDraft.hintsCount[payload.rNb],
          ...payload.hintArray.map((el) => el + 1)
        )
        sliceDraft.hintsCount[0] = 0
        for (let i = 1; i <= NUMBER_OF_RIDDLES; i++) {
          sliceDraft.hintsCount[0] += sliceDraft.hintsCount[i]
        }
      })
      return sliceNext

    // ---------------------------------------------------------
    case isTimeUpUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.isTimeUp = true
        sliceDraft.isOpenTimeUp = true
      })
      return sliceNext

    // ---------------------------------------------------------
    case isOpenFeedbackFalse.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.isOpenFeedback = false
      })
      return sliceNext

    // ---------------------------------------------------------
    case isOpenTimeUpFalse.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.isOpenTimeUp = false
      })
      return sliceNext

    // ---------------------------------------------------------
    case isToBeLoginShownUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.isToBeLoginShown = !sliceDraft.isToBeLoginShown
      })
      return sliceNext

    // ---------------------------------------------------------
    case isOnPauseUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.isOnPause = payload.isOnPause
      })
      return sliceNext

    // ---------------------------------------------------------
    case isDebugOnUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.isDebugOn = !sliceDraft.isDebugOn
        sliceDraft.isResetForm = true
      })
      return sliceNext

    // ---------------------------------------------------------
    case toBeLoginNameUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.toBeLoginName = payload.toBeLoginName
        sliceDraft.toBeLoginCode = keyGen(payload.toBeLoginName)
      })
      return sliceNext

    // ---------------------------------------------------------
    case durationSuccessUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        const durationSuccessInSec =
          SETUP.durationInSec - durationToSeconds(payload.timeDelta)
        sliceDraft.durationSuccess = secondsToDuration(durationSuccessInSec)
      })
      return sliceNext

    // ---------------------------------------------------------
    case languageUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.language = payload.language
      })
      return sliceNext

    // ---------------------------------------------------------
    default:
      return sliceNext
  }
}

export default game
