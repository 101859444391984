
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import i07 from "../../img/1280/16x9/insta_feed_hochkant.jpg"

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Image = makeShortcode("Image");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Image src={i07} mdxType="Image" />
    <h1>{`Insta-Feed`}</h1>
    <p>{`Du hast nun Zugriff auf Bella's Instagram Profil. Wusstest du das social media
Bilder beim Identitätsdiebstahl verwendet werden, um weitere Fake-Profile wie
z.B auf Datingplattformen zu erstellen. Auf wie vielen Bildern ist Bella's
Gesicht erkennbar.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;