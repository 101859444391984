
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import i10 from "../../img/1280/16x9/2fa.jpg"

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Image = makeShortcode("Image");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Image src={i10} mdxType="Image" />
    <h1>{`Zwei-Faktor-Authetifizierung`}</h1>
    <p>{`Wie du gerade gelernt hast, ist eine zweifache Authentifikation wichtig, um sich
vor unbefugtem Zugriff zu schützen. Was also musst du in dein Handy eingeben, um
die zu verifizieren? Was ist der Bestätigungscode aus dem Beispiel.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;