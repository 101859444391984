
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Über`}</h1>
    <h2>{`Rätsel`}</h2>
    <p>{`Der Inhalt für das Spiel einschließlich der Mini-Rätsel wurden von uns beiden
erarbeitet.`}</p>
    <h2>{`Technologie and Programmierung`}</h2>
    <p>{`Technik: Lilli Anders; Grafiken & Texte: Inken Adam`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;