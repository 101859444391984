import { Link as LinkRouter } from "react-router-dom"
import { Container, Flex, Link, Image } from "@chakra-ui/react"
import logoSecIT from "../../img/svg/Logo_secIT.png"
import SelectLanguage from "../SelectLanguage"
import { SETUP } from "../../const/setup"
import { TRANSLATIONS } from "../../const/translations"

const PageHeader = ({ game }) => {
  //=================================================================

  const { languages } = SETUP
  const { isDebugOn, language } = game

  return (
    <Container w="100%" color="white" bg="rgb(132,196,196)" px={4} pt={4}>
      <Link as={LinkRouter} to="/">
        <Image src={logoSecIT} w={64} mb={2} />
      </Link>
      <Flex justify="space-between" align="center">
        <Link as={LinkRouter} to="/" display="flex" _hover={{ color: "white" }}>
          
        </Link>
        <Flex align="center">
          {isDebugOn && (
            <Link as={LinkRouter} to="/store" ml={4}>
              Store
            </Link>
          )}
          <Link as={LinkRouter} to="/license" ml={4}>
            {TRANSLATIONS[language].license}
          </Link>
          <Link as={LinkRouter} to="/about" ml={4} mr={2}>
            {TRANSLATIONS[language].about}
          </Link>
          {languages.length > 1 && <SelectLanguage />}
        </Flex>
      </Flex>
    </Container>
  )
}

export default PageHeader
