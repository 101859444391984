
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import i03 from "../../img/1280/16x9/passwort.jpg"

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Image = makeShortcode("Image");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Image src={i03} mdxType="Image" />
    <h1>{`Passwort`}</h1>
    <p>{`Jetzt hast du gelernt, wie du dir Passwörter leichter merken kannst. Guck dir
die Grafik nochmal an. Welches Passwort ergibt sich aus dem Satz?`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;