
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import i11 from "../../img/1280/16x9/ausgefülltes_formular1024_1.jpg"
import i12 from "../../img/1280/16x9/Scan.jpeg"

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Image = makeShortcode("Image");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Image src={i11} mdxType="Image" />
    <h1>{`Du hast es geschafft!`}</h1>
    <p>{`Du hast erfolgreich Bellas Identität gestohlen!`}</p>
    <p>{`Und? War doch gar nicht so schwer oder? Also solltest du es dem Hacker zukünftig
nicht so einfach machen, wie Bella. Natürlich war das ein Extrembeispiel.`}</p>
    <p>{`Hat der Hacker erstmal eins deiner Passwörter, geht es schneller als du denkst!
Also denk dran, dich in Zukunft besser zu schützen. Du hast es eh schon gemacht
und da war nichts neues für dich dabei? - Na umso besser :)`}</p>
    <p>{`Falls dir das alles zu schnell ging, kannst du dich gerne nochmal durch alle
Infos klicken, indem du auf die einzelnen Themenfelder klickst. Viel Spaß noch
und danke für's Spielen!`}</p>
    <p>{`Hier kannst du checken, ob und wenn ja wann deine E-Mail schonmal geleakt wurde.`}</p>
    <Image src={i12} mdxType="Image" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;