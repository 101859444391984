import React, { useRef, useEffect } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { TRANSLATIONS } from "../../const/translations"
import { SETUP } from "../../const/setup"

import {
  Flex,
  Box,
  Text,
  Input,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react"

import { codeUpdate, isOpenFeedbackFalse } from "../../redux/actions/game"
import { randomPickFromArray } from "../../functions/utils/random"

const FormCode = ({ rNb, game, dispatch, isResetForm = false, ...rest }) => {
  //=================================================================
  let navigate = useNavigate()

  const cancelRef = useRef()
  const { register, handleSubmit, reset } = useForm()

  const {
    loginNameDefault,
    loginCodeDefault,
    solvedLang,
    hintsLang,
    hasHints,
    feedbackHeaderYes,
    feedbackHeaderNo,
    feedbackBodyYes,
    feedbackBodyNo,
    gameStartedHead,
    gameStartedBody,
    loginFailedHead,
    loginFailedBody,
    msgSuccessHead,
    msgSuccessBody,
  } = SETUP

  const {
    attemptsLang,
    attemptsCount,
    solved,
    hintsCount,
    isSuccess,
    isOpenFeedback,
    isDebugOn,
    riddlePageURLs,
    language,
  } = game

  useEffect(() => {
    if (isResetForm) {
      reset()
    }
  }, [isResetForm, reset])

  const onClose = () => {
    if (isSuccess) {
      navigate(riddlePageURLs[0])
    } else if (solved[rNb]) {
      navigate(riddlePageURLs[rNb + 1])
    }
    dispatch(isOpenFeedbackFalse())
  }

  const onSubmit = (data) => {
    // send riddle code
    const payload = { rNb, code: data.code }
    // potentially add loginName
    if (data.loginName) {
      payload.loginName = data.loginName
    }
    dispatch(codeUpdate(payload))
  }

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <Box mb={8} {...rest}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="Off">
        <Flex align="center" wrap="wrap">
          {rNb === 0
            ? !solved[0] && (
                <Box>
                  <Flex mb={2} align="center">
                    <Text w="64px" mr={4}>
                      {TRANSLATIONS?.[language].email}
                    </Text>
                    <Input
                      type="text"
                      {...register("loginName", {
                        required: true,
                        maxLength: 80,
                      })}
                      defaultValue={isDebugOn ? loginNameDefault : ""}
                      w="240px"
                    />
                  </Flex>
                  <Flex align="center">
                    <Text w="64px" mr={4}>
                      Code:
                    </Text>
                    <Input
                      type="password"
                      {...register("code", { required: true, maxLength: 80 })}
                      defaultValue={isDebugOn ? loginCodeDefault : ""}
                      w="240px"
                    />
                    <Button type="submit" mr={4}>
                      OK
                    </Button>
                  </Flex>
                </Box>
              )
            : !solved[rNb] && (
                <Flex align="center">
                  <Text w="64px" mr={4}>
                    Code:
                  </Text>
                  <Input
                    type="text"
                    {...register("code", { required: true, maxLength: 80 })}
                    w="160px"
                  />
                  <Button type="submit" mr={4}>
                    OK
                  </Button>
                </Flex>
              )}

          {rNb > 0 && (
            <>
              <Text mx={4}>{solvedLang[language]}: </Text>
              {solved[rNb] ? (
                <Text color="green.600">✔</Text>
              ) : (
                <Text color="red.600">✘</Text>
              )}

              <Text mx={4}>{attemptsLang}: </Text>
              <Text>{attemptsCount[rNb]} </Text>

              {hasHints && (
                <>
                  <Text mx={4}>{hintsLang[language]}: </Text>
                  <Text>{hintsCount[rNb]} </Text>
                </>
              )}
            </>
          )}
        </Flex>
      </form>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpenFeedback}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            {rNb === 0 &&
              (solved[rNb]
                ? gameStartedHead[language]
                : loginFailedHead[language])}
            {rNb > 0 &&
              (isSuccess
                ? msgSuccessHead[language]
                : solved[rNb]
                ? randomPickFromArray(feedbackHeaderYes[language])
                : randomPickFromArray(feedbackHeaderNo[language]))}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {rNb === 0 &&
              (solved[rNb]
                ? gameStartedBody[language]
                : loginFailedBody[language])}
            {rNb > 0 &&
              (isSuccess
                ? msgSuccessBody[language]
                : solved[rNb]
                ? randomPickFromArray(feedbackBodyYes[language])
                : randomPickFromArray(feedbackBodyNo[language]))}
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    game: state.game,
  }
}

export default connect(mapStateToProps)(FormCode)
